import React from "react";
import Layout from "components/layout";
import Philadelphia from "components/locationIndexPages/Philadelphia/index";
import SEO from "components/seo";

function index() {
  const title = "Philadelphia Translation Services - Translation Agency";
  const description =
    "Searching for translation services in Philadelphia? Tomedes provides translation, localization, and interpretation services in 120+ languages with rapid delivery and 24/7 support.";
  const keywords = "Translation Services in Philadelphia";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug="/locations/philadelphia-translation-services"
      />
      <Philadelphia />
    </Layout>
  );
}

export default index;
